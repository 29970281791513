<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-md-2">
              <div class="col-sm-12 col-md-6">
                <div class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select v-model="datatable.queries.per_page" class="ml-1 mr-1" size="sm"
                      :options="[10, 15, 25, 50, 100]" @change="onQueryChange"></b-form-select>&nbsp;entries
                  </label>
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <div class="dataTables_filter text-md-right">
                  <button class="btn btn-success btn-sm mr-2" @click="showImport">
                    <i class="uil uil-import"></i> Import
                  </button>

                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input v-model="datatable.queries.search" type="search" placeholder="Search..."
                      class="form-control form-control-sm ml-2"></b-form-input>
                  </label>
                  <button class="ml-1 btn btn-primary btn-sm" @click="onQueryChange('search')">
                    <i class="uil uil-search-alt"></i>
                  </button>
                </div>
              </div>
            </div>
            <template v-if="!fetching">
              <b-table :items="businesses" :fields="datatable.columns" :sort-by.sync="datatable.queries.sort_by"
                :sort-desc.sync="datatable.queries.sort_desc" :per-page="datatable.queries.per_page"
                :no-local-sorting="true" @sort-changed="onQueryChange">
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
                <template v-slot:cell(msg_limit)="data">
                  <span v-if="data.item.no_messages !== null" class="font-weight-bold" :class="{
                    'text-danger': data.item.usage_messages >= data.item.no_messages,
                    'text-warning': data.item.no_messages > data.item.usage_messages && data.item.usage_messages >= data.item.no_messages * .9,
                    'text-success': data.item.usage_messages * .9 > data.item.usage_messages,
                  }">{{ data.item.usage_messages }} / {{ data.item.no_messages }}</span>
                  <span v-else class="font-weight-bold">{{ data.item.usage_messages }}</span>
                </template>
                <template v-slot:cell(contact_limit)="data">
                  <span v-if="data.item.no_contacts !== null" class="font-weight-bold" :class="{
                    'text-danger': data.item.usage_contacts >= data.item.no_contacts,
                    'text-warning': data.item.no_contacts > data.item.usage_contacts && data.item.usage_contacts >= data.item.no_contacts * .9,
                    'text-success': data.item.usage_contacts * .9 > data.item.usage_contacts,
                  }">{{ data.item.usage_contacts }} / {{ data.item.no_contacts }}</span>
                  <span v-else class="font-weight-bold">{{ data.item.usage_contacts }}</span>
                </template>
                <template v-slot:cell(usage_cost_month)="data">
                  <span class="text-align-center">$ {{ data.item.usage_cost_month.toFixed(3) }}</span>
                </template>
                <template v-slot:cell(active)="data">
                  <b-form-checkbox v-model="data.item.active" switch
                    @change="handleChangeActive(data.item.id)"></b-form-checkbox>
                </template>


                <template v-slot:cell(reset_date)="data">
                  {{
                    data.item.reset_date
                    | moment(
                      `MM-DD`
                    )
                  }}
                </template>
                <template v-slot:cell(phone_number)="data">
                  <span v-if="data.item.phone_number">
                    {{ data.item.phone_number | nationalNumber }}
                  </span>
                </template>
                <template v-slot:cell(actions)="data">
                  <div style="margin-top: -10px; min-width: 80px;">
                    <b-button v-b-tooltip.hover variant="primary" size="sm" title="Sign In" class="mr-1"
                      :disabled="loadingImpersonate" @click="impersonate(data.item.owner.id)">
                      <b-spinner v-if="loadingImpersonate" small />
                      <i v-else class="uil uil-sign-out-alt"></i>
                    </b-button>
                    <b-dropdown v-b-tooltip.hover variant="black" right toggle-class="arrow-none text-muted pr-0"
                      title="More">
                      <template v-slot:button-content>
                        <i class="uil uil-bars text-primary"></i>
                      </template>
                      <b-dropdown-item @click="share(data.item)">
                        <i class="uil uil-share-alt mr-2"></i>Share
                      </b-dropdown-item>
                      <b-dropdown-item :to="{
                        name: 'agency.businesses.edit',
                        params: { id: data.item.id },
                      }">
                        <i class="uil uil-edit mr-2"></i>Edit
                      </b-dropdown-item>
                      <b-dropdown-item @click="remove(data.item)">
                        <i class="uil uil-trash mr-2"></i>Delete
                      </b-dropdown-item>
                      <b-dropdown-item @click="resetNumber(data.item)">
                        <i class="uil uil-refresh mr-2"></i>Reset Number
                      </b-dropdown-item>
                      <b-dropdown-item v-if="!data.item.is_template" @click="onShowInfo(data.item)">
                        <i class="uil uil-eye mr-2"></i>Information
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </template>
              </b-table>
              <div class="row mt-4">
                <div class="col">
                  <div class="float-left mt-2">
                    Total {{ totalBusinesses }} businesses
                  </div>
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <b-pagination v-model="datatable.queries.page" :total-rows="totalBusinesses"
                        :per-page="datatable.queries.per_page" @change="onQueryChange"></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </template>
            <div v-else class="text-center">
              <b-spinner></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal v-model="showDeleteModal" title="Delete Business">
      <p v-if="selectedBusiness">Are you sure you want to delete business <b>"{{
        selectedBusiness.name
      }}"</b>? <br />The business will be archived for 15 days and then permanently deleted.</p>
      <template v-slot:modal-footer>
        <b-button variant="light" @click="showDeleteModal = false">Cancel</b-button>
        <b-button variant="danger" @click="handleRemove">
          <b-spinner v-if="processing" small />
          <i v-else class="uil uil-refresh"> Delete</i>
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="showResetNumberModal" title="Reset Business Number">
      <p v-if="selectedBusiness">Are you sure you want to reset number of business <b>"{{
        selectedBusiness.name
      }}"</b>?</p>
      <template v-slot:modal-footer>
        <b-button variant="light" @click="showResetNumberModal = false">Cancel</b-button>
        <b-button variant="danger" @click="handleResetNumber">
          <b-spinner v-if="processing" small />
          <i v-else class="uil uil-refresh"> Reset</i>
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="showWelcomeModal" size="lg" title="Welcome">
      <div class="text-center">
        <h4 class="text-success">Thanks For Signing Up For Customers Engine</h4>
        <p>
          We have autonomically created a demo account for you called Creekside
          Pizza. You can use this account to demo the system to your prospects.
          The account automatically updates daily so there always has fresh
          account data for you to demo. When you log in for the first time you
          will need to assign a number to the account before you can access it.
        </p>
      </div>
      <template v-slot:modal-footer>
        <b-button variant="success" @click="onCloseWelcomeModal">Ok</b-button>
      </template>
    </b-modal>

    <b-modal v-model="showShareModal" title="Share business" size="lg">
      <div>
        <textarea ref="shareLink" v-model="shareLink" cols="30" rows="6" class="form-control" readonly
          @click="copyLink"></textarea>
        <div class="text-right mt-1">
          <a href="javascript:void(0)" @click="copyLink">
            Copy the link
          </a>
        </div>
      </div>
      <template v-slot:modal-footer>
        <b-button variant="primary" @click="copyLink"><i class="uil uil-copy"></i> Copy</b-button>
        <b-button variant="light" @click="showShareModal = false">Cancel</b-button>
      </template>
    </b-modal>

    <b-modal v-model="isShowImport" title="Import business" size="lg">
      <div>
        <TextInput v-model="importLink" label="Link" />
      </div>
      <template v-slot:modal-footer>
        <b-button variant="primary" :disabled="!importLink" @click="importBusiness">
          <b-spinner v-if="loadingImport" small />
          <span v-else>
            <i class="uil uil-import"></i> Import
          </span>
        </b-button>
        <b-button variant="light" @click="isShowImport = false">Cancel</b-button>
      </template>
    </b-modal>
    <b-modal id="two-way-modal" v-model="showSubscriptionLivechat" size="lg" hide-footer hide-header>
      <div class="modal-detail">
        <div class="modal-tag">
          <span>Viral SMS Loops</span>
        </div>
        <h5>
          Supercharge your business' list building efforts with the Viral Loops Function.
        </h5>
        <p>
          Get this feature for all of your businesses for just $97/month
        </p>
        <ul>
          <li>
            <i class="uil uil-check"></i>
            <p>Set up Powerful Viral SMS Loops in under 4 minutes.</p>
          </li>
          <li>
            <i class="uil uil-check"></i>
            <p>Generate warm leads faster with multiple Unlocking Rewards</p>
          </li>
          <li>
            <i class="uil uil-check"></i>
            <p>Supercharge your Sales using smart automations for each new reward tier.</p>
          </li>
          <li>
            <i class="uil uil-check"></i>
            <p>Make more money per customer in your Digital Agency by charging extra for Viral Loop Campaigns.</p>
          </li>
        </ul>

        <div class="text-center">
          <a class="btn btn-success btn-lg" href="javascript:;" @click="onTwoWayGetStartedClick">
            Get Started Now
          </a>
        </div>
      </div>
    </b-modal>
    <b-modal v-model="showInfoModal" title="Business Information" size="lg">
      <div v-if="selectedBusiness" class="d-block biz-info-wrapper">
        <div class="limit-line"><span>Message Limit:</span> <b><span v-if="selectedBusiness.no_messages">{{
          selectedBusiness.no_messages }} / month</span><span v-else>Unlimited</span></b></div>
        <div class="limit-line"><span>Message Usage:</span> <b>{{ selectedBusiness.usage_messages }} / month</b></div>
        <div class="limit-line"><span>Contact Limit:</span> <b>{{ selectedBusiness.no_contacts ?
          selectedBusiness.no_contacts : 'Unlimited' }}</b></div>
        <div class="limit-line"><span>Contact Usage:</span> <b>{{ selectedBusiness.usage_contacts }}</b></div>
        <div class="limit-line"><span>Cost:</span> <b>$ {{ selectedBusiness.usage_cost_month ?
          selectedBusiness.usage_cost_month.toFixed(3) : 0 }} / month</b>
        </div>
        <div class="limit-line"><span>Carrier Fee:</span> <b>$ {{ selectedBusiness.carrier_fee_cost ?
          selectedBusiness.carrier_fee_cost.toFixed(3) : 0 }} /
            month</b></div>
      </div>
      <div v-else class="text-center">
        <b-spinner></b-spinner>
      </div>
      <template v-slot:modal-footer>
        <b-button variant="primary" @click="showInfoModal = false">Got It</b-button>
      </template>
    </b-modal>

  </div>
</template>

<script>
import Vue from 'vue'
import moment from 'moment-timezone'
import { mapState } from 'vuex';

export default {
  data() {
    return {
      datatable: {
        columns: [
          { key: 'id', label: '#', sortable: true },
          { key: 'name', sortable: true },
          // { key: 'owner.email', label: 'Email', sortable: true },
          { key: 'phone_number', label: 'Number', sortable: false },
          { key: 'reset_date', label: 'Reset At' },
          { key: 'msg_limit', label: 'Messages', sortable: false },
          { key: 'contact_limit', label: 'Contacts', sortable: false },
          { key: 'usage_cost_month', label: 'Cost', sortable: true },
          { key: 'active' },
          { key: 'actions' },
        ],
        queries: {
          per_page: 10,
          page: 1,
          status: 'active',
          search: null,
          sort_by: 'id',
          sort_desc: true,
        },
      },
      showDeleteModal: false,
      selectedBusiness: null,
      loadingImpersonate: false,
      processing: null,
      showWelcomeModal: false,
      showShareModal: false,
      shareLink: null,
      isShowImport: false,
      importLink: null,
      loadingImport: false,
      showSubscriptionLivechat: false,
      showResetNumberModal: false,
      showInfoModal: false,
    }
  },

  computed: {
    ...mapState('business', {
      fetching: 'fetching',
      didFetch: 'didFetch',
      queries: 'queries',
      businesses: 'businesses',
      totalBusinesses: 'total',
    }),

    isAdminImpersonation() {
      return localStorage.getItem('main_access_role') === 'Admin'
    },

    user() {
      return this.$store.getters['auth/user']
    },

    config() {
      return this.$store.getters['app/config']
    },
  },

  watch: {
  },

  created() {
  },

  mounted() {
    this.$store.dispatch('business/resetAll')
      .then(() => {
        this.onQueryChange();
      })

    if (this.user.agency.is_new) {
      this.showWelcomeModal = true

      this.$store.dispatch('agency/toggleIsNew', this.user.agency)
    } else {
      this.showLivechatModal();
    }
  },

  methods: {
    onShowInfo(business) {
      this.selectedBusiness = null
      this.getBusinessInfo(business.id)
      this.showInfoModal = true
    },

    showLivechatModal() {
      if (this.user.role === 'Agency' && !this.user.agency.is_enabled_loop) {
        let lastShowTwoWayKey = localStorage.getItem('lastShowTwoWay');
        if (!lastShowTwoWayKey) {
          lastShowTwoWayKey = moment().subtract(1, 'days').format('MM-DD')
        }

        if (lastShowTwoWayKey < moment().format('MM-DD') && !this.user.agency.is_template) {
          localStorage.setItem('lastShowTwoWay', moment().format('MM-DD'))
          this.showSubscriptionLivechat = true
        }
      }
    },
    onCloseWelcomeModal() {
      this.showWelcomeModal = false
      this.showLivechatModal();
    },

    onTwoWayGetStartedClick() {
      this.showSubscriptionLivechat = false
      // this.$router.push({ name: 'agency.twoway' })
      this.$router.push({ name: 'agency.loop' })
    },

    onQueryChange(type = undefined) {
      this.$nextTick(() => {
        if (type === 'search') {
          this.datatable.queries.page = 1;
        }
        this.$store.commit('business/updateState', {
          queries: {
            per_page: this.datatable.queries.per_page,
            page: this.datatable.queries.page,
            status: this.datatable.queries.status,
            search: this.datatable.queries.search,
            sort_by: this.datatable.queries.sort_by,
            sort_desc: this.datatable.queries.sort_desc,
          }
        })
        this.getBusinesses()
      });
    },

    getBusinesses() {
      this.$store
        .dispatch('business/getAll')
    },

    getBusinessInfo(id) {
      this.processing = true
      this.$store
        .dispatch('business/getInfo', id)
        .then((res) => {
          this.processing = null
          this.selectedBusiness = res.data
        })
        .catch(() => {
          this.processing = null
        })
    },

    impersonate(userId) {
      this.loadingImpersonate = true

      this.$store
        .dispatch('auth/impersonate', userId)
        .then((res) => {
          this.$store
            .dispatch('auth/getProfile')
            .then((user) => {
              this.$router.push('/' + user.role.toLocaleLowerCase())
              this.loadingImpersonate = false
            })
            .catch(() => {
              this.loadingImpersonate = false
            })
        })
        .catch(() => {
          this.loadingImpersonate = false
        })
    },

    remove(business) {
      this.showDeleteModal = true
      this.selectedBusiness = business
    },

    handleRemove() {
      this.processing = this.selectedBusiness.id
      this.$store
        .dispatch('business/archive', this.selectedBusiness.id)
        .then(() => {
          this.processing = null
          this.showDeleteModal = false
          this.getBusinesses()
        })
        .catch(() => {
          this.processing = null
        })
    },

    handleChangeActive(business) {
      this.$store
        .dispatch('business/toggleActive', business)
        .then(() => {
        })
        .catch(() => {
        })
    },

    share(business) {
      this.shareLink = '/'
      this.showShareModal = true

      const { whitelabel } = this.config
      if (this.config.custom_domain && whitelabel && whitelabel.is_ssl_cert_exists && whitelabel.is_vhost_exists) {
        this.shareLink = `https://${this.config.custom_domain}/agency/businesses/${business.unique_id}`;
      } else {
        this.shareLink = `https://${this.config.subdomain}.${this.config.whitelabel.domain}/agency/businesses/${business.unique_id}`
      }
    },

    copyLink() {
      this.$refs.shareLink.select()
      this.$refs.shareLink.setSelectionRange(0, 999999)
      document.execCommand('copy')

      Vue.$toast.open({
        message: 'Copied',
        type: 'success',
        duration: 5000,
      })
    },

    showImport() {
      this.importLink = ''
      this.isShowImport = true
    },

    importBusiness() {
      this.loadingImport = true
      const businessId = this.importLink.replace(/.+\/businesses\//, '')

      this.$store
        .dispatch('business/findByUniqueId', businessId)
        .then((res) => {
          this.loadingImport = false
          this.isShowImport = false
          this.$router.push({ name: 'agency.businesses.share', params: { id: businessId } })
        })
        .catch(() => {
          this.loadingImport = false
        })
    },

    resetNumber(business) {
      this.showResetNumberModal = true
      this.selectedBusiness = business
    },

    handleResetNumber() {
      this.processing = this.selectedBusiness.id
      this.$store
        .dispatch('business/resetNumber', this.selectedBusiness.id)
        .then(() => {
          this.processing = null
          this.showResetNumberModal = false
          this.getBusinesses()
        })
        .catch(() => {
          this.processing = null
        })
    },
  },
}
</script>

<style lang="scss">
#two-way-modal.show {
  display: flex !important;
  align-items: center;
  justify-content: center;

  .modal-body {
    background: #ff914b url('~~~@assets/images/loops.png') no-repeat;
    background-size: 22%;
    background-position: center left;
    background-position-x: 40px;
  }

  .modal-tag {
    text-align: center;
    margin-bottom: 15px;

    span {
      color: #1e6c32;
      background: #d0ffdc;
      font-weight: 700;
      padding: 5px 10px;
      border-radius: 5px;
    }
  }

  .modal-detail {
    margin-left: 250px;
    background: #FFF;
    padding: 20px;
    padding-left: 80px;
    position: relative;
    overflow: hidden;

    &:before {
      content: " ";
      height: 118%;
      width: 261px;
      display: block;
      position: absolute;
      top: -28px;
      left: -193px;
      background: #ff914b;
      border-radius: 0 50% 50% 0;
    }
  }

  ul {
    padding-left: 0px;
    list-style: none;
    margin-bottom: 30px;

    li {
      color: #0e0e0e;
      margin-bottom: 10px;
      font-weight: 700;
      display: flex;
      align-items: center;

      i {
        background: #30ab51;
        color: #FFF;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        display: inline-block;
        padding: 1px 2px;
        margin-right: 20px;
      }

      p {
        margin-bottom: 0;
      }
    }
  }

  @media screen and (max-width: 998px) {
    .modal-body {
      background: transparent;

      .modal-detail {
        padding-left: 0px;
        margin-left: 0px;

        &:before {
          display: none;
        }
      }
    }
  }
}

.biz-info-wrapper {
  padding: 10px 30px;

  .limit-line {
    padding-top: 5px;

    span {
      display: inline-block;
      width: 150px;
    }
  }
}
</style>